.homepage {
  .section {
    margin-bottom: 20px;
  }
  .booking {
    // enlarge width
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
    //
    margin-top: -18px;
    padding: 20px;
    background-color: #89C740;
  }
}