.login-page {
  background-image: url(https://i.ibb.co/CJ97yhg/login-background.jpg);
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
  min-height: 500px;
  margin-top: -5rem;
  background-size: cover;
  .custom-padding-top {
    padding-top: 15%;
  }
  .custom-padding-bottom {
    padding-bottom: 15%;
  }
  .login-form {
    background-image: url(https://i.ibb.co/jgsBf7C/login-background-2.jpg);
    background-size: contain;
    background-repeat: round;
    padding: 1rem;
    display: flex;
    width: 20rem;
    height: 40rem;
    border-radius: 30px;
    margin-left: auto;
    margin-right: auto;
    form {
      margin-top: 70%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      .sign-in,
      .sign-up,
      .forgot-password,
      .reset-password {
        input {
          width: 100%;
        }
        .options {
          display: inline-flex;
          width: 100%;
          font-size: 0.7em;
          .option1 {
            width: 50%;
            text-align: left;
          }
          .option2 {
            width: 50%;
            text-align: right;
          }
        }
        .custom-login-btn {
          width: 60%;
          margin-top: 1rem;
          margin-left: auto;
          margin-right: auto;
          button {
            background-color: rgb(58, 175, 58);
          }
          button:hover {
            background-color: green;
          }
        }
        .customTxt {
          font-size: 0.7em;
          display: inline-flex;
          color: whitesmoke;
          button {
            color: whitesmoke;
            padding: 0;
            border: none;
            height: 0;
            margin-left: 0.7em;
            text-decoration: underline;
          }
          button:hover {
            color: green;
          }
        }
        .customBtn {
          color: whitesmoke;
          padding: 0;
          border: none;
          height: 0;
          margin-left: 0.7em;
          text-decoration: underline;
        }
        .customBtn:hover {
          color: green;
        }
      }
      .sign-in,
      .sign-up,
      .forgot-password {
        margin-top: 38%;
        .custom-input {
          margin-bottom: 5%;
          display: inline-flex;
          background-color: white;
          border-radius: 10px;
          border: thin solid green;
          input[type='password'],
          input[type='number'],
          input[type='text'],
          input[type='email'],
          input[type='password']:active,
          input[type='text']:active,
          input[type='number']:active {
            color: whitesmoke;
            margin-right: 10px;
            border: none;
          }
        }
      }
      .sign-up {
        margin-top: 0%;
        .custom-signup-btn {
          width: 60%;
          margin: 5% auto;
          button {
            background-color: rgb(58, 175, 58);
          }
          button:hover {
            background-color: green;
          }
        }
        .remember-checkbox {
          font-size: 0.7em;
          color: whitesmoke;
          input {
            width: auto;
          }
        }
      }
      .forgot-password {
        .custom-forget-btn {
          width: 60%;
          margin: 5% auto;
          input[type='button'] {
            border-radius: 10px;
          }
        }
        h4 {
          margin-top: 2rem;
        }
      }
    }
  }
  input[type='password'],
  input[type='number'],
  input[type='text'],
  input[type='email'],
  input[type='password']:active,
  input[type='text']:active,
  input[type='number']:active {
    color: whitesmoke;
    margin-right: 10px;
    border: none;
    padding: 0.5rem;
  }
}
