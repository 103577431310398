.btnLink {
    margin: 10px 0;
    a {
        border-radius: 4px;
        text-decoration: none;
        border: 1px solid gray;
        padding: 2px 4px;
        color: black;
    }
    a:hover {
        background-color: rgb(110, 226, 143);
        color: #000;
    }
}