.promotionCard {
    
  background-color: antiquewhite;
  .image {
    img {
      width: 100%;
      height: 290px;
      border-radius: 4px;
    }
  }
  .content {
    padding: 10px;
  }
}
