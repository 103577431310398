.user-menu {
    .nav-item.dropdown {
        a {
            color: black !important;
            font: 1rem sans-serif;
        }
    }
}

@media screen and (max-width: 990px) {
    .user-menu {

    }
}
