.header1 {
  font-size: 41px;
  font-family: fangsong;
  color: rgb(255, 255, 255);
  background-size: cover;
  .mainContain {
    position: relative;
    margin-top: -100px;
    video {
      width: 100%;
    }
    .container-fluid {
      margin-bottom: 25px;
    }
    .groupText {
      position: absolute;
      bottom: 0;
      font-size: 4.5vw;
      padding: 20px;
      text-transform: uppercase;
      width: 100%;
      .groupText1 {
        .text1 {
          text-align: right;
          margin-right: 16%;
        }
        .text2 {
          text-align: right;
          margin: 0;
        }
        p {
            margin-bottom: 0;
            font-size: 0.8em;
            opacity: 50%;
        }
      }
      .groupText2 {
        font-size: 2.5vw;
        text-align: left;
      }
    }
  }
}

.header2 {
  font-family: fangsong;
  color: rgb(255, 255, 255);
  background-size: cover;
  background-image: url(https://i.ibb.co/NnFJTnd/header1.jpg);
  background-position: right;
  background-repeat: no-repeat;
  height: 450px;
  nav {
    background-color: transparent;
  }
}
