.accommodations-page {
  text-align: center;
  .page-title {
    p {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .product {
    margin: 2rem;
    .product-intro {
      .react-multi-carousel-list.carousel-container {
        margin-top: 2rem;
      }
      li.react-multi-carousel-item {
        margin: 1rem;
      }
      article {
        width: 70%;
        margin: 2rem auto;
        text-align: center !important;
      }
    }
  }
  .booking-now {
    button {
      background-color: #89C740;
      width: 30vw;
    }
    margin: 5vw 0;
  }
}
