.user-detail {
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  .title {
    text-align: center;
    padding: 2vw;
  }
  .left-menu {
    background-color: rgb(227, 233, 219);
    .first-row {
      padding: 5px;
      .image {
        img {
        border-radius: 50%;
        height: 60px;
        width: 60px !important;
        }
      }
    }
  }
}
