.lb-navbar {
  .navbar {
    position: sticky;
    top: 0;
    background-color: transparent !important;
    z-index: 1;
    .navbar-brand {
      .image {
        width: 48px;
      }
    }
    #navbarScroll {
      .nav-home {
        display: none;
      }
      .me-auto.my-2.my-lg-0.navbar-nav {
        margin-left: 18%;
      }
      .me-auto.my-2.my-lg-0.navbar-nav.highlight {
        background-color: #31833b !important;
        border-radius: 5px;
      }
      a {
        color: whitesmoke;
        background-color: #8cc54014;
        h6 {
          margin-bottom: 0;
        }
      }
      a:hover {
        color: purple;
      }
      .language-picker {
        a {
          color: black;
        }
      }
      a:hover,
      button:hover {
        padding: 10px;
        border-radius: 4px;
        background-color: #8cc540;
        font-family: 'Dancing Script';
      }
    }

    .language-picker button {
      padding: 5px;
      text-decoration: none;
      margin-top: 0;
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
    }

    .container-fluid button {
      font-family: 'Dancing Script';
      padding: 5px;
      a {
        color: rgb(0, 0, 0);
      }
      .navbar-toggler-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
}
@media screen and (min-width: 1288px) {
  .lb-navbar {
    .navbar {
      position: sticky;
      top: 0;
      background-color: transparent !important;
      z-index: 1;
      #navbarScroll {
        .me-auto.my-2.my-lg-0.navbar-nav {
          margin-left: 25%;
        }
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .lb-navbar {
    .navbar {
      #navbarScroll {
        .me-auto.my-2.my-lg-0.navbar-nav {
          margin-left: 0;
        }
        .me-auto.my-2.my-lg-0.navbar-nav.highlight {
          background-color: white;
        }
        a {
          color: rgb(0, 0, 0);
        }
        .nav-home {
          display: inherit;
        }
        a:hover,
        button:hover {
          padding: 10px;
          border-radius: 4px;
          background-color: #8cc540;
          color: rgb(122, 4, 161);
        }
      }

      .navbar-collapse {
        margin-top: -3rem;
      }
      .navbar-brand {
        visibility: hidden;
      }
      .navbar-toggler {
        z-index: 1;
      }
      .container-fluid {
        margin-bottom: 25px;
      }
    }

    .btn-group {
      .dropdown-menu {
        padding: 0 20px;
      }
      display: inline;
      .dropdown-toggle.show.nav-link {
        color: rgb(66, 5, 30);
      }
    }
    .sub-dropdown-menu {
      button {
        margin-top: 8px;
        margin-bottom: 14px;
      }
    }
  }
}
