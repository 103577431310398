#app {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Dancing Script';
  }
  font-family: Arial, Helvetica, sans-serif;
  article {
    text-indent: 2em;
    text-align: justify;
  }
  img {
    width: 100%;
  }
  svg {
    color: #198754;
  }
  .text-red {
    color: #eb0140;
  }
  .ml-3 {
    margin-left: 3px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
}
