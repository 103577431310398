.storiesPage {
  text-align: center;
  img {
    width: 100%;
  }
  .section {
    margin: 20px 0;
    h4 {
      font-family: calibri, sans-serif;
      font-size: 3rem;
      margin-bottom: 10px;
      text-align: center;
    }
    .intro {
      text-align: justify;
    }
  }
  .introLebruder {
    article {
      column-count: 2;
      text-align: left;
    }
  }
}

@media screen and (max-width: 990px) {
  .storiesPage {
    .introLebruder {
      article {
        column-count: 1;
        text-align: left;
      }
    }
  }
}