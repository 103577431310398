.spaService {
  text-align: center;
  img {
    width: 100%;
    max-width: 600px;
  }
  .title {
    font-family: 'Dancing Script', 'PT Serif', sans-serif;
    font-size: 2rem;
    margin-bottom: 10px;
  }
}
