.postScript {
  text-align: center;
  .image {
    text-align: right;
    img {
      width: 400px;
    }
  }
  .script {
    width: 400px;
    text-align: left;
  }
  .title {
    font-family: "Dancing Script", "PT Serif", sans-serif;
    font-size: 2rem;
    margin-bottom: 10px;
    text-align: center;
  }
}
@media screen and (max-width: 990px) {
  .postScript {
    text-align: center;
    .image {
      text-align: center;
      img {
        width: 400px;
        max-width: 100%;
      }
    }
    .script {
      width: 400px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
    .title {
      font-family: "Dancing Script", "PT Serif", sans-serif;
      font-size: 2rem;
      margin-bottom: 10px;
      text-align: center;
    }
  }
}
