.room {
    padding: 15px;
    border-radius: 4px;
    border: thin solid gray;
    margin-bottom: 20px;
    .priceInfo {
        text-align: right;
        .price {
            color: rgb(82, 3, 114);
        }
    }
    .packageOffer {
        margin-bottom: 10px;
    }
}