.short-booking-card {
  border: 1px solid gainsboro;
  border-radius: 1rem;
  padding: 1rem;
  margin: 1rem;
  .header, .footer {
    display: inline-flex;
    width: 100%;
    position: relative;
    .right-item {
      position: absolute;
      right: 0;
    }
  }
  .header {
    border-bottom: 1px solid gainsboro;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  .content {
    .image {
      width: auto;
      max-width: 12rem;
      img {
        border-radius: 4px;
      }
    }
    margin-bottom: 1rem;

  }
}