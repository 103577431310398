.promotionCard {
  background-color: #8d5ab6;
  text-align: center;
  margin: 20px;
  a {
    color: white;
    text-decoration: none;
  }
  a:hover {
    color: white;
  }
  .image {
    overflow: hidden;
    img {
      width: 100%;
      height: 290px;
      border-radius: 4px;
    }
    img:hover {
      transform: scale(1.2);
      transition: 1.5s;
    }
  }
  .content {
    padding: 10px;
  }
}

@media screen and (max-width: 990px) {
}