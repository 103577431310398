.accommodation {
  .header-image {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
    margin-top: -8vw;
    img {
      width: 100%;
    }
  }
  .main-content {
    text-align: center;
    margin: 10%;
  }
}