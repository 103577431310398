.bookingComponent {
  .section1 {
    text-align: right;
    padding-right: 20px;
    .innerCol {
      .baseInput > div{
        height: 35px;
      }
      padding: 0 2px;
      display: inline-block;
      .label {
        text-align: left;
      }
      .inputWrapper {
        background-color: white;
        border-radius: 4px;
        border: thin solid gray;
        // max-width: 120px;
        input {
          margin: 0 10px;
          border: none;
          max-width: 100px;
        }
        input:focus {
          outline: none;
        }
      }
    }
  }
  .section2 {
    display: inline;
    .innerCol {
      padding: 0 2px;
      display: inline-block;
      position: relative;
      .baseInput > div{
        height: 35px;
      }
      .inputWrapper {
        background-color: white;
        border-radius: 4px;
        border: thin solid gray;
        min-width: 100px;
        input {
          margin-left: 10px;
          border: none;
        }
        input:focus {
          outline: none;
        }
        .icon {
          position: relative;
          right: 2px;
          bottom: 2px;
        }
      }
    }
  }
  .searchBtn {
    margin-top: 32px;
    .baseInput > button{
      height: 35px;
    }
  }
}
