.section {
  .title {
    font-family: "Dancing Script", "PT Serif", sans-serif;
    font-size: 2rem;
    margin-bottom: 10px;
    text-align: center;
    .image {
      img {
        min-height: 500px;
      }
    }
  }
  .subTitle {
    text-align: center
  };
  .card {
    margin: 10px;
  }

  .smallScreen {
    display: none;
  }
}