.contact-us {
    background-color: rgb(227, 233, 219);
    margin-left: auto;
    margin-right: auto;
    margin: 0 15%;
    margin-top: -8%;
    .title {
        text-align: center;
        padding: 2vw;
    }
    .content {
        padding: 1vw;
        .submit-form {
            margin: 2vw;
            input, textarea {
                width: 100%;
                border-radius: 4px;
                border: thin solid purple;
            }
        }
    }
    .map-view {
        position: relative;
        margin-bottom: 3rem;
        .map {
            margin: 0 -22vw;
        }
    }
}