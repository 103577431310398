.booking {
  position: relative;
  margin-bottom: 20px;
  .roomList {
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    .room {
      margin-bottom: 20px;
    }
  }
  .sideBooking {
    border: thin solid green;
    border-radius: 4px;
    padding: 10px;
    position: sticky;
    right: 0;
    top: 0;
    .title {
      border-bottom: thin solid green;
    }
    .rightCmpnt {
      margin-top: 1.5rem;
      text-align: right;
    }
    .section {
      padding: 10px 0;
      border-bottom: thin solid green;
    }
    button {
      border-radius: 4px;
      border: thin solid green;
      width: 100%;
    }
  }
}
