.photoCard {
  position: relative;
  margin: 4px;
  img {
    // width: 100%;
    height: 400px;
    max-height: 400px;
  }
  p {
    position: absolute;
    right: 16px;
    bottom: 10px;
    left: 10px;
    color: white;
    text-transform: uppercase;
  }
}
