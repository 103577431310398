.tourDetail {
  position: relative;
  .article {
    background-color: gray;
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
  }
  .sideBooking {
    background-color: aquamarine;
    position: sticky;
    right: 0;
    top: 0;
  }
  margin-bottom: 20px;
}
