.my-promotion-card {
	border: 1px solid gainsboro;
  border-radius: 1rem;
  margin: 1rem;
  .image {
    img {
      border-radius: 1rem;
    }
  }
  .coupon-info {
    padding: 1rem;
    .use-coupon {
      text-align: right;
    }
  }
}
