.language-picker {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    svg {
        display: inline;
        margin: 6px;
    }
    .show.dropdown {
        .dropdown-menu.show {
            left: -4rem;
            top: 3rem;
        }
    }
}

@media screen and (max-width: 990px) {
    .language-picker {
        .show.dropdown {
            .dropdown-menu.show {
                left: 0;
            }
        }
    }
}
