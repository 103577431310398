.packageOffer {
  border-radius: 4px;
  border: thin solid gray;
  padding: 10px;
  margin-bottom: 10px;
  .title {
    display: inline-flex;
    span {
      svg {
        font-size: 1.2rem;
        color: #198754;
      }
    }
    button {
      border: none;
      background-color: transparent;
    }
  }
  .prices {
    text-align: right;
  }
  span {
    margin-right: 10px;
    svg {
      font-size: 1rem;
      color: rgb(93, 0, 242);
    }
  }
  ul {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
  }
}
