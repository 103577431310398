.footer {
  background-color: rgb(30, 0, 105);
  .col1 {
    padding: 30px;
  }
  .col, a, .col1 {
    color: #fff;
    text-decoration: none;
  }
  .col {
    padding: 20px;
  }
  .logo {
    img {
      width: 100%;
      max-width: 300px;
      margin-bottom: 10px;
    }
  }
  a:hover {
    color: greenyellow;
  }
  .title {
    color: #ffa200
  }
}
